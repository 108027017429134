import Head from "@/components/Head/index.vue";
import Tabs from "@/components/Tabs/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import { defineComponent, ref, watch, nextTick, onActivated } from "vue";
import List from "./components/List.vue";
export default defineComponent({
  name: "Investigate",
  components: {
    Head: Head,
    Pagination: Pagination,
    Tabs: Tabs,
    List: List
  },
  setup: function setup() {
    var titleArr = ["待参活动", "历史记录"];
    var tabsIndex = ref(0);
    var table1 = ref();
    var table2 = ref();
    var totalNum = ref(0); // 标签卡切换

    function tabsChange(val) {
      tabsIndex.value = val;
    } // 获取详情


    function getList() {
      nextTick(function () {
        if (tabsIndex.value == 0) {
          table1.value.getTabledata();
        } else {
          table2.value.getTabledata();
        }
      });
    } // 监听tabs索引值改变、pager分页改变


    watch(function () {
      return [tabsIndex.value];
    }, function () {
      if (tabsIndex.value == 0) {
        table1.value.initData();
        totalNum.value = table1.value.totalNum;
      } else {
        table2.value.initData();
      }
    });
    getList(); // 页面激活钩子函数

    onActivated(function () {
      // 重新加载
      getList();
    });
    return {
      titleArr: titleArr,
      totalNum: totalNum,
      tabsIndex: tabsIndex,
      table1: table1,
      table2: table2,
      tabsChange: tabsChange
    };
  }
});